<template>
  <div class="home_title" style="font-size: 14px">
    <span class="title" :style="{ borderColor: borderColor }">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
      type: String
    },
    borderColor: {
      default: '#fff',
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.home_title {
  box-sizing: border-box;
  position: relative;
  padding: 10px 0px 4px 10px;
  margin: 5px 5px;
  line-height: 10px;
  border-radius: 5px;
  .title {
    display: inline-block;
    border-left: 3px solid;
    padding-left: 5px;
    height: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 10px;
    vertical-align: middle;
    transform: scale(0.9);
  }
}
</style>

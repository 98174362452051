<template>
  <div class="operation-log-container">
    <div class="log-header">
      <HomeTitle title="最新动态" borderColor="blue" />
      <div class="dot-list">
        <span class="dot has-message"></span>
        <span>最新</span>
        <!-- <span class="dot"></span>
        <span>已读</span> -->
      </div>
    </div>
    <div class="content">
      <LogItem v-if="origin === 'https://app.mediatom.cn' && hasMessage " :hasMessage="hasMessage" :messageRecord="messageRecord" />
      <LogItem v-for="(item, i) in datas" :key="i" :itemInfo="item" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HomeTitle from '../../components/homeTitle'
import mixDate from '@/mixins/initDate'
import { getOperationLog } from '@/api/dashboard'
import LogItem from './logItem.vue'
export default {
  components: { HomeTitle, LogItem },
  mixins: [mixDate],
  data () {
    return {
      query: {
        startTime: '',
        endTime: ''
      },
      datas: [],
      messageRecord: undefined,
      // 是否能跳转到消息中心
      hasMessage: false
    }
  },
  computed: {
    ...mapState({
      origin: (state) => state.autoweb.origin
    })
  },
  mounted () {
    const { endDate, startDate } = this.initDate(2, 0)
    this.query.startTime = startDate
    this.query.endTime = endDate
    this.getOperationLog()
  },
  methods: {
    toOperationLog () {
      this.$router.push({
        path: '/accountInfo/operationLog'
      })
    },
    async getOperationLog () {
      const resp = await getOperationLog(this.query)
      // 是否显示消息
      this.hasMessage = resp.data.hasMessage
      this.messageRecord = resp.data.items[0]
      if (this.hasMessage) {
        this.datas = resp.data.items.splice(1, 4)
      } else {
        this.datas = resp.data.items.splice(0, 5)
      }
    },
    getOperateMotion (type) {
      // ，0=未知,1=增，2=改，3=查，4=删,5=登录，6=退出
      return type === '0'
        ? '未知'
        : type === '1'
        ? '增加'
        : type === '2'
        ? '修改'
        : type === '3'
        ? '查询'
        : type === '4'
        ? '删除'
        : type === '5'
        ? '登录'
        : type === '6'
        ? '退出'
        : ''
    },
    getOperateType (type) {
      //  1 应用 2 广告位 3瀑布流属性配置 4 广告平台 5 数据预警 6 账户信息
      //  7 账户权限 8 接入中心 9 广告源 10 A/B测试 11 流量分组 12 账户
      return type === '1'
        ? '应用'
        : type === '2'
        ? '广告位'
        : type === '3'
        ? '瀑布流属性配置'
        : type === '4'
        ? '广告平台'
        : type === '5'
        ? '数据预警'
        : type === '6'
        ? '账户信息'
        : type === '7'
        ? '账户权限'
        : type === '8'
        ? '接入中心'
        : type === '9'
        ? '广告源'
        : type === '10'
        ? 'A/B测试'
        : type === '11'
        ? '流量分组'
        : type === '12'
        ? '账户'
        : ''
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.operation-log-container {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  // overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  .content {
    flex-grow: 1;
    height: 0;
    position: relative;
    margin: 10px 8px;
    border-radius: 10px;
    // overflow: hidden;
  }
}
.log-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
.dot-list{
  display: flex;
  margin-right: 18px;
  align-items: center;
  font-size: 12px;
  .dot{
        display: inline-block;
        box-sizing: content-box;
        min-width: 8px;
        height: 8px;
        background-color: #C5C2C2;
        vertical-align: middle;
        border-radius: 50%;
        margin-right: 5px;
        border: 4px solid #E5E5E6;
        margin-left: 10px;
        &.has-message{
          border-color: #f8cfcf;
          background-color: #E02020;
        }
      }
}
}
</style>

<template>
  <div class="log-item-container">
    <div class="event">
      <div class="wrapper">
        <span class="dot" :class="{hasMessage}"></span>
        <span class="info top-message" @click="goMessage" :title="messageRecord.operateContent" v-if="hasMessage">
          {{ messageRecord.operateContent }}
        </span>
        <span
          class="info"
          v-else
          :title="`【${getOperaName(itemInfo.type)}】${getOperateDesc(itemInfo)}`"
          @click="toOperationLog">
          <!-- 【{{ getOperaName(itemInfo.type) }}】 -->
          {{
            getOperateDesc(itemInfo)
          }}
        </span>
      </div>
    </div>
    <div class="time">
      <span v-if="hasMessage">{{ messageRecord.operateTime.substring(5, 100) }}</span>
      <span v-else>{{ itemInfo.operateTime.substring(5, 100) }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      // 展示小红点
      showDot: false
    }
  },
  props: {
    itemInfo: {
      default: () => ({
        operateTime: '',
        operateUser: ''
      }),
      type: Object
    },
    messageRecord: {
      type: Object,
      default: () => ({
        operateTime: '',
        operateContent: ''
      })
    },
    hasMessage: {
      type: Boolean,
      default: undefined
    }
  },
  computed: {
    ...mapState({
      log: (state) => state.user.info.log // 操作菜单权限
    })
  },
  methods: {
    getOperateMotion (type) {
      // ，0=未知,1=增，2=改，3=查，4=删,5=登录，6=退出
      return type === '0'
        ? '未知'
        : type === '1'
        ? '增加'
        : type === '2'
        ? '修改'
        : type === '3'
        ? '查询'
        : type === '4'
        ? '删除'
        : type === '5'
        ? '登录'
        : type === '6'
        ? '退出'
        : ''
    },
    getOperateType (type) {
      //  1 应用 2 广告位 3瀑布流属性配置 4 广告平台 5 数据预警 6 账户信息
      //  7 账户权限 8 接入中心 9 广告源 10 A/B测试 11 流量分组 12 账户  15竞价广告
      return type === '1'
        ? '应用'
        : type === '2'
        ? '广告位'
        : type === '3'
        ? '瀑布流属性配置'
        : type === '4'
        ? '广告平台'
        : type === '5'
        ? '数据预警'
        : type === '6'
        ? '账户信息'
        : type === '7'
        ? '账户权限'
        : type === '8'
        ? '接入中心'
        : type === '9'
        ? '广告源'
        : type === '10'
        ? 'A/B测试'
        : type === '11'
        ? '流量分组'
        : type === '12'
        ? '账户'
        : type === '15'
        ? '竞价广告'
        : ''
    },
    toOperationLog () {
      if (this.log !== 1) return
      this.$router.push({
        path: '/accountInfo/operationLog'
      })
    },
    goMessage () {
      if (this.hasMessage) {
        this.$router.push({
          path: '/accountInfo/Message',
          query: { type: 2 }
        })
      } else {
        this.toOperationLog()
      }
    },
    getOperaName (type) {
      type = +type
      if ([5, 6].includes(type)) {
        return '登录信息'
      } else {
        return '操作日志'
      }
    },
    getOperateDesc (item) {
      const type = +item.type
      if ([5, 6].includes(type)) {
        return item.operateUser + (type === 5 ? '登录' : '退出')
      } else {
        return item.operateUser + this.getOperateMotion(item.operateMotion) + this.getOperateType(item.operateType)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.top-message {
  width: 277px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: bold;
  padding-right: 20px;
}
.log-item-container {
  height: 100%;
  height: 20%;
  position: relative;
  display: flex;
  border-bottom: 1px dashed #e8e8e8;
  // &:last-child{
    // border: none;
  // }
  .time,
  .event {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    flex-wrap: nowrap;
  }
  .time {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    width: 40%;
  }
  .event {
    width: 60%;
    padding: 2px 0;
    position: relative;
    .wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 5px;
      padding-left: 10px;
      span.info {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
      }
      .dot{
        display: inline-block;
        box-sizing: content-box;
        min-width: 8px;
        height: 8px;
        background-color: #C5C2C2;
        vertical-align: middle;
        border-radius: 50%;
        margin-right: 5px;
        border: 4px solid #E5E5E6;
        &.hasMessage{
          border-color: #f8cfcf;
          background-color: #E02020;
        }
      }
    }
    span {
      &:hover {
        color: @primary-color;
        cursor: pointer;
      }
    }
  }
}
</style>
